import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '@/views/Home.vue'
import Requests from '@/views/requests/Requests.vue'
import RequestsDetails from '@/views/requests/RequestsDetails.vue'
import RequestResults from '@/views/requests/RequestResults.vue'
import RequestResultsExport from '@/views/requests/RequestResultsExport.vue'
import RequestResultsProcessing from '@/views/requests/RequestResultsProcessing.vue'
import Help from '@/views/Help.vue'
import Privacy from '@/views/Privacy.vue'
import Config from '@/views/Config.vue'
import Admin from '@/views/Admin.vue'
import Login from '@/components/Login.vue'
import Profile from '@/components/Profile.vue'
import QuestionModelsManager from '@/views/config/QuestionModelsManager.vue'
import SurveyModelsManager from '@/views/config/SurveyModelsManager.vue'
import FacultiesSurveysLinkManager from '@/views/config/FacultiesSurveysLinkManager.vue'
import ConfigIndex from '@/views/config/Index.vue'
import AdminIndex from '@/views/admin/Index.vue'
import MassEvaluation from '@/views/admin/MassEvaluation.vue'
import RequestsList from '@/views/requests/RequestsList.vue'
import Teaching from '@/components/form/Teaching.vue'
import Surveyparams from '@/components/form/Surveyparams.vue'
import Targetaudience from '@/components/form/Targetaudience.vue'
import Summary from '@/components/form/Summary.vue'
import store from '@/store/index.js'
import { validateRole } from '@/helpers/authutils';
import AdminSurveyCreator from "@/components/form/AdminSurveyCreator.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter(to, from, next) {
      const urlParams = new URLSearchParams(window.location.search)

      if (urlParams.has('routename')) {
        sessionStorage.setItem('routename', urlParams.get('routename') ?? '')
      }

      if (!urlParams.has('token')) {
        window.location.href = process.env.VUE_APP_AUTH_URL + "/login";
      } else {
        store.dispatch('auth/login').then(() => {
          if (sessionStorage.getItem('redirect_url')?.indexOf('results') !== -1) {
            let id = sessionStorage.getItem('redirect_url')?.split('/')[4]
            next({name: 'request_results', params: {id}})
          } else if (sessionStorage.getItem('redirect_url')?.indexOf('detail') !== -1) {
            let id = sessionStorage.getItem('redirect_url')?.split('/')[4]
            next({name: 'request_detail', params: {id}})
          } else {
            next({name: 'Home'})
          }
        })
      }
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },

  //Add Reauests
  {
    path: '/requests/add',
    component: Requests,
    beforeEnter(to, from, next) {
      next()
    },
    children: [
      {
        path: "teaching",
        component: Teaching,
        name: "teaching"
      },
      {
        path: "targetaudience",
        component: Targetaudience,
        name: "targetaudience"
      },
      {
        path: "surveyparams",
        component: Surveyparams,
        name: "surveyparams"
      },
      {
        path: "summary",
        component: Summary,
        name: "summary"
      },
    ]
  },
  {
    path: "/requests/list",
    component: RequestsList,
    name: "request_list"
  },
  {
    path: "/requests/:id/detail",
    component: RequestsDetails,
    name: "request_detail",
  },
  {
    path: "/requests/:id/detail/survey_edit",
    component: AdminSurveyCreator,
    name: "admin_survey_creator",
    beforeEnter(to, from, next) {
      if (sessionStorage.getItem('currentrole') === 'admin') {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: "/requests/:id/results",
    component: RequestResults,
    name: "request_results"
  },
  {
    path: "/requests/:id/results_processing",
    component: RequestResultsProcessing,
    name: "request_results_processing",
    beforeEnter(to, from, next) {
      if (sessionStorage.getItem('currentrole') === 'admin'|| sessionStorage.getItem('currentrole') === 'editor') {
        next();
      } else {
        next('/');
      }
    },
  },

  {
    path: '/config',
    component: Config,
    beforeEnter(to, from, next) {
      if (sessionStorage.getItem('currentrole') === 'admin') {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: "index",
        component: ConfigIndex,
        name: "configindex"
      },
      {
        path: "questionmodels",
        component: QuestionModelsManager,
        name: "questionmodels"
      },
      {
        path: "surveymodels",
        component: SurveyModelsManager,
        name: "surveymodels"
      },
      {
        path: "facultiessurveyslinks",
        component: FacultiesSurveysLinkManager,
        name: "facultiessurveyslinks"
      }
    ],
  },

  {
    path: '/admin',
    component: Admin,
    beforeEnter(to, from, next) {
      if (sessionStorage.getItem('currentrole') === 'admin') {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: "index",
        component: AdminIndex,
        name: "adminindex"
      },
      {
        path: "massevaluation",
        component: MassEvaluation,
        name: "massEvaluation"
      },
      {
        path: "/requests/:id/results_export",
        component: RequestResultsExport,
        name: "request_results_export"
      },
    ],
  },

  // Others
  {
    path: '/disconnect',
    name: 'Disconnect',
    redirect: '/'
  },
  {
    path: '/contact',
    component: Help
  },
  {
    path: '/privacy',
    component: Privacy
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: Home,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.getters['auth/token']) {
    next({name: 'Login'})
  } else {
    if (window.location.href.indexOf('token') === -1) {
      sessionStorage.setItem('redirect_url', window.location.href)
    }
    if (validateRole()) {
      next()
    }
  }
})

export default router
