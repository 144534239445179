<template>
  <div class="p-grid">
    <div class="p-col-2">
      <Button @click="$router.go(-1)" icon="pi pi-backward" class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>

  <h1>{{ $t('request_results_processing.title') + ' - no ' + request?.number}}</h1>
  <p v-if="current_role==='admin' || status !== requestHelper().ANONYMISED">{{ request?.teaching_name }} - {{ request?.semester === 'P' ? $t('misc.spring') : $t('misc.autumn') }} {{ request?.semester === 'P' ? request?.academic_year + 1 : request?.academic_year }}</p>
  <p v-if="current_role==='admin' || status !== requestHelper().ANONYMISED">{{ request?.speakers.map(speaker => speaker.name).join(', ') }}</p>

  <Toolbar>
    <template #start>
      <Button @click="$router.push({'name': 'request_results'})" icon="pi pi-chart-bar"
              class="p-mr-2 online"
              :label="$t('request_detail.access_results')"/>
      <Button v-if="current_role === 'admin' && status === requestHelper().ANONYMISED" :label="$t('request_results_processing.send_results')" icon="pi pi-envelope" class="p-button-success p-mr-2"
              @click="sendResults(true)"/>
      <Button v-if="current_role === 'admin' && status === requestHelper().ANONYMISED" :label="$t('request_results_processing.send_results_without_notification')" icon="pi pi-check" class="p-button-warning p-mr-2"
              @click="sendResults(false)"/>
      <Button v-if="(current_role === 'admin' || current_role === 'editor') && status === requestHelper().PROCESSING_RESULTS" :label="$t('request_results_processing.anonymisation_complete')" icon="pi pi-user" class="anonymised p-mr-2"
              @click="setAnonymisationComplete"/>
      <Button label="Export CSV des réponses" icon="pi pi-file-excel" @click="$router.push({name: 'request_results_export'})"></Button>
    </template>

    <template #end>
    </template>
  </Toolbar>

  <TabView :active-index="active" @tab-click="activate_results">
    <TabPanel header="Données quantitatives">
      <h4> {{ $t('request_results.participants') }} : {{ survey_results.length }} </h4>
      <p>Appréciation globale : {{ displayedGlobalAcceptancePercentage }}%</p>
      <DataTable :value="matrix_questions_answers" showGridlines stripedRows responsiveLayout="scroll">
        <template #header>
          <div style="text-align:left">
            <MultiSelect :modelValue="selected_matrix_questions_columns" :options="matrix_questions_columns"
                         optionLabel="title" @update:modelValue="onToggle"
                         placeholder="Select Columns" style="width: 20em"/>
          </div>
        </template>
        <Column field="total" header=""></Column>
        <Column v-for="question of selected_matrix_questions_columns" :field="question.hash"
                :header="question.title"></Column>
      </DataTable>
    </TabPanel>

    <TabPanel header="Données qualitatives">
      <TabView :active-index="active_comments_tab" id="qualitative-data">
        <TabPanel>
          <template #header>
            <div class="flex align-items-center gap-2">
              <i class="pi pi-eye"></i>
              <span class="font-bold white-space-nowrap"> Texte affiché</span>
            </div>
          </template>
          <DataTable :value="comments_list" showGridlines stripedRows editMode="cell" @cell-edit-complete="onCellEditComplete" class="editable-cells-table">
            <Column v-for="question of open_questions_list"
                    :class="'p-col-' + 12/open_questions_list.length" :key="question.name"
                    :field="question.type === 'comment' ? question.name+'_cleaned' : question.name+'-Comment_cleaned'"
                    :header="question.type === 'comment' ? question.title[$i18n.locale] ?? question.title : question.commentText">
              <template #editor="{ data, field }">
                <Textarea autoResize cols="80" v-model="data[field]" autofocus/>
              </template>
            </Column>
          </DataTable>
        </TabPanel>
        <TabPanel>
          <template #header>
            <div class="flex align-items-center gap-2">
              <i class="pi pi-eye-slash"></i>
              <span class="font-bold white-space-nowrap"> Texte original</span>
            </div>
          </template>
          <DataTable :value="comments_list" showGridlines stripedRows>
            <Column v-for="question of open_questions_list"
                    :class="'p-col-' + 12/open_questions_list.length" :key="question.name"
                    :field="question.type === 'comment' ? question.name+'_original' : question.name+'-Comment_original'"
                    :header="question.type === 'comment' ? question.title[$i18n.locale] ?? question.title : question.commentText"></Column>
          </DataTable>
        </TabPanel>
      </TabView>
    </TabPanel>
  </TabView>
</template>

<script>
import RequestResults from "@/views/requests/RequestResults";
import RequestService from "@/Services/request.service";
import ResultsService from "@/Services/results.service";
import {generalhelper as generalHelper, generalhelper} from "@/helpers/generalhelper";
import {useRoute} from "vue-router";
import ReportsService from "@/Services/reports.service";
import {requesthelper} from "@/helpers/requesthelper";

export default {
  name: 'request-results-editing',
  components: {
    RequestResults
  },

  mounted() {
    this.current_role = sessionStorage.getItem('currentrole') ?? ''
    this.request_id = useRoute().params.id
    RequestService.getRequest(this.request_id).then(res => {
      this.request = res.data.result
    })
    this.getData()
    //switch tab in comments using keyboard arrows
    document.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowRight') {
        this.active_comments_tab = 1
      } else if (event.key === 'ArrowLeft') {
        this.active_comments_tab = 0
      }
    })
  },
  data() {
    return {
      current_role: '',
      request_id: null,
      request: null,
      status: null,
      survey: null,
      survey_results: [],
      open_questions_list: [],
      matrix_questions_list: [],
      matrix_questions_answers: [],
      matrix_questions_columns: [],
      selected_matrix_questions_columns: [],
      totalRow: {},
      globalAcceptancePercentage: 0,
      displayedGlobalAcceptancePercentage: 0,
      comments_list: [],
      comments_array: [],
      show_results: false,
      active: 0,
      active_comments_tab: 0,
      reminders: [],
      participants: [],
    }
  },
  methods: {
    requestHelper() {
      return requesthelper
    },
    generalHelper() {
      return generalHelper
    },
    getCommentQuestions() {
      JSON.parse(this.survey).pages.forEach(page => {
        page.elements.forEach(question => {
          //Either the question is a comment or it has a comment associated with it
          if (question.type === 'comment' || question.commentText) {
            this.open_questions_list.push(question)
          } else if (question.type === 'matrix') {
            this.matrix_questions_list.push(question)
          }
        })
      })
    },
    /**
     * Creates an array with answers to matrix questions in the following format :
     * [
     *  [id => participant_1_id, "hash(question_1_title)"=> answer_to_q1, ... , "hash(question_n_title)"=> answer_to_qn],
     *  ...,
     *  [id => participant_p_id, "hash(question_1_title)"=> answer_to_q1, ... , "hash(question_n_title)"=> answer_to_qn],
     * ]
     * The hash is mandatory for allowing Datatable to match the columns with the rows (doesn't work with question titles)
     */
    getMatrixQuestionsAnswers() {
      this.survey_results.forEach(result => {
        let answer = {}
        answer.id = result._id
        this.matrix_questions_list.forEach(questiongroup => {
          let questions = result.json_definition[questiongroup.name] ?? {}
          Object.keys(questions).forEach(question_title => {
            this.matrix_questions_columns.push({
              title: question_title,
              hash: generalhelper.getHash(question_title).toString()
            })
            answer[generalhelper.getHash(question_title)] = questions[question_title]
          })
        })
        this.matrix_questions_answers.push(answer)
      })
      this.matrix_questions_columns = generalhelper.getUniqueValuesBy(this.matrix_questions_columns, 'hash')
    },

    /**
     * Parses the comments from the survey results and creates an array with the following format :
     * [
     * {id: result_1_id, question1_original: original_answer, question1_cleaned: cleaned_answer, ...},
     * ...
     * {id: result_n_id, question1_original: original_answer, question1_cleaned: cleaned_answer, ...}
     * ]
     * The cleaned answer is the one that is displayed to the teachers, the original answer is the one that was initially submitted.
     * There are two cases :
     * 1. The question is a comment
     * 2. The question is not a comment but has a comment associated with it
     */
    parseComments() {
      this.survey_results.forEach(result => {
        let comment = {
          id: result._id,
        }
        this.open_questions_list.forEach(question => {
          if (question.type === 'comment' && result.original_json_definition && JSON.parse(result.original_json_definition)[question.name] !== undefined) {
            comment[question.name + '_original'] = JSON.parse(result.original_json_definition)[question.name]
            comment[question.name + '_cleaned'] = result.json_definition[question.name]
          } else if(question.type !== 'comment' && question.commentText && JSON.parse(result.original_json_definition)[question.name + '-Comment'] !== undefined) {
            comment[question.name + '-Comment_original'] = JSON.parse(result.original_json_definition)[question.name + '-Comment']
            comment[question.name + '-Comment_cleaned'] = result.json_definition[question.name + '-Comment']
          }
        })
        if (Object.keys(comment).length > 1) {
          this.comments_list.push(comment)
        }
      })
    },
    /**
     * Adds a row to the results tab with totals for each column
     */
    computeTotals() {
      ReportsService.getDetailedAcceptance([this.request], false).then(res => {
        if(res.data.data.length !== 0) {
          res.data.columns.forEach(column => {
            const key = generalhelper.getHash(column.title).toString()
            const value = res.data.data[this.request_id][column.hash]
            this.totalRow[key] = value === null ? null : value + '%'
          })
          this.totalRow.total = this.$t('request_results.results_processing.total')
          this.matrix_questions_answers.push(this.totalRow)
          this.computeGlobalAcceptancePercentage()
        }
      })
    },
    computeGlobalAcceptancePercentage() {
      let total_considered_columns = 0
      this.matrix_questions_columns.forEach(column => {
        if (this.totalRow[column.hash] !== undefined && this.totalRow[column.hash] !== null) {
          this.globalAcceptancePercentage += Number(this.totalRow[column.hash].slice(0, -1))
          total_considered_columns += 1
        }
      })
      this.globalAcceptancePercentage = Math.round(this.globalAcceptancePercentage / total_considered_columns)
      this.displayedGlobalAcceptancePercentage = this.globalAcceptancePercentage
    },
    onCellEditComplete(event) {
      let result = this.survey_results.find(el => el._id === event.newData.id)
      if (typeof result.json_definition === 'string') {
        result.json_definition = JSON.parse(result.json_definition)
      }

      Object.keys(event.newData).forEach(key => {
        if (key !== 'id' && !key.includes('_original')) {
          result.json_definition[key.replace('_cleaned', '')] = event.newData[key]
        }
      })
      result.json_definition = JSON.stringify(result.json_definition)
      ResultsService.updateResult(result).then(() => {
        ResultsService.getResults(this.request_id).then(data => {
          this.survey_results = []
          this.comments_list = []
          data.data.forEach(result => {
            if (result.json_definition) {
              result.json_definition = JSON.parse(result.json_definition)
              this.survey_results.push(result)
            }
          })
          this.parseComments()
        })

        this.$toast.add({
          severity: 'success',
          summary: 'Comment saved',
          life: 3000
        })
      })
    },
    onToggle(value) {
      this.selected_matrix_questions_columns = this.matrix_questions_columns.filter(col => value.includes(col))
      this.displayedGlobalAcceptancePercentage = 0
      let total_considered_columns = 0
      this.selected_matrix_questions_columns.forEach(column => {
        if (this.totalRow[column.hash] === null) return
        this.displayedGlobalAcceptancePercentage += Number(this.totalRow[column.hash].slice(0, -1))
        total_considered_columns += 1
      })

      this.displayedGlobalAcceptancePercentage = total_considered_columns === 0
        ? this.globalAcceptancePercentage
        : Math.round(this.displayedGlobalAcceptancePercentage / total_considered_columns)
    },
    getData() {
      this.survey_results = []
      this.open_questions_list = []
      this.matrix_questions_list = []
      this.matrix_questions_answers = []
      this.matrix_questions_columns = []
      this.selected_matrix_questions_columns = []
      this.comments_list = []

      Promise.all([
        ResultsService.getResults(this.request_id),
        RequestService.getRequest(this.request_id)
      ])
        .then(data => {
          data[0].data.forEach(result => {
            if (result.json_definition) {
              result.json_definition = JSON.parse(result.json_definition)
              this.survey_results.push(result)
            }
          })
          this.survey = data[1].data.result.original_survey
          this.status = data[1].data.result.status
          this.getCommentQuestions()
          this.getMatrixQuestionsAnswers()
          this.parseComments()
          this.computeTotals()
        })
      RequestService.getRequest(this.request_id).then(res => {
        res.data.result.steps.forEach(step => {
          if (step.type === 'remind') {
            this.reminders.push(step)
          }
        })
      })
    },
    setAnonymisationComplete() {
      RequestService.changeRequestStatus(this.request_id, requesthelper.ANONYMISED).then(res => {
        this.request = res.data.result
        this.getData()
        this.$toast.add({
          severity: 'success',
          summary: this.$t('request_results_processing.anonymisation_complete'),
          life: 3000
        })
      })
    },
    sendResults(notify_teachers) {
      this.$confirm.require({
        target: event.currentTarget,
        icon: 'pi pi-exclamation-triangle',
        message: this.$t('misc.are_you_sure'),
        accept: () => {
          RequestService.changeRequestStatus(this.request_id, requesthelper.RESULTS_AVAILABLE, this.globalAcceptancePercentage, notify_teachers).then(() => {
            this.$toast.add({
              severity: 'success',
              summary: this.$t('request_results_processing.send_results_confirmation'),
              life: 3000
            })
            this.$router.push({'name': 'request_results'})
          })
        }
      })

    },
    activate_results(event) {
      if (event.index === 2) {
        this.show_results = true
      }
    },
  }
}
</script>
<style>
td {
  word-break: break-word;
}

#qualitative-data .p-tabview-panels {
  overflow: scroll;
  height: 800px;
}
</style>
