<template>
  <div>
    <Card>
      <template v-slot:title>{{ $t("form.step3.title") }}</template>
      <template v-slot:subtitle>{{ $t("form.step3.title") }}</template>
      <template v-slot:content>
        <div class="p-fluid vertical-container p-grid">

          <div class="p-field p-col-12">
            <label class="required">{{ $t("form.step3.fields.surveytype.existing.surveychoice.label") }}</label>
            <Dropdown :options=surveys optionLabel="_id" v-model="originalsurvey"
                      @change="setOriginalSurveyId($event)"></Dropdown>
            <iframe class="p-col-12" v-if="original_survey_id"
                    :src="BACKEND_URL + '/survey_models/preview/' + original_survey_id"></iframe>
          </div>

          <div class="p-field p-col-6" v-show="originalsurvey">
            <label class="p-mr-2 required">{{ $t("form.step3.fields.surveytype.label") }}</label>
            <i class="pi pi-question-circle" v-tooltip="$t('')"></i>

            <SelectButton v-model="survey_type" :class="{'p-invalid': validationErrors.surveytype && submitted}"
                          :options="[{name: $t('form.step3.fields.surveytype.existing.label'), value: 'existing'}, {name: $t('form.step3.fields.surveytype.custom.label'), value: 'custom'}]"
                          @change="setDatesAccordingToSurveyType"
                          optionLabel="name" optionValue="value" :allowEmpty="false"/>
            <small v-show="validationErrors.survey_type && submitted"
                   class="p-error">{{ $t("form.step1.fields.surveytype.error") }}</small>
          </div>

          <div class="p-field p-col-6" v-show="survey_type==='custom'">
            <label class="p-mr-2">{{ $t("form.step3.fields.surveytype.modifications.label") }}</label>
            <Textarea :class="{'p-invalid': validationErrors.survey_modifications && submitted}"
                      v-model="survey_modifications" rows="5" cols="30"/>
            <small v-show="validationErrors.survey_modifications && submitted"
                   class="p-error">{{ $t("form.step3.fields.surveytype.modifications.error") }}</small>
          </div>


          <!--        *** Dates ***         -->
          <Divider align="left" v-show="originalsurvey">
            <div class="inline-flex align-items-center">
              <i class="pi pi-calendar p-mr-2"></i>
              <span>Dates</span>
            </div>
          </Divider>

          <div class="p-field p-col-3" v-show="originalsurvey">
            <label class="required">{{ $t("form.step3.fields.sendingdate.label") }}</label>
            <Calendar :class="{'p-invalid': validationErrors.sending_date && submitted}" v-model="sendingdate"
                      :min-date="min_sending_date"
                      :showTime="true" :disabledDays="[0,6]" dateFormat="dd.mm.yy"
                      @date-select="setDates('sending')"/>
            <small v-show="validationErrors.sending_date && submitted"
                   class="p-error">{{ $t("form.step1.fields.sendingdate.error") }}</small>
          </div>

          <div class="p-field p-col-3" v-show="originalsurvey">
            <label class="required">{{ $t("form.step3.fields.firstreminder.label") }}</label>
            <Calendar :class="{'p-invalid': validationErrors.first_reminder_date && submitted}"
                      v-model="firstreminderdate" :showTime="true" :min-date="min_first_reminder_date"
                      :disabledDays="[0,6]"
                      dateFormat="dd.mm.yy" @date-select="setDates('firstreminder')"/>
            <small v-show="validationErrors.first_reminder_date && submitted"
                   class="p-error">{{ $t("form.step1.fields.firstreminderdate.error") }}</small>
          </div>

          <div class="p-field p-col-3" v-show="originalsurvey">
            <label>{{ $t("form.step3.fields.secondreminder.label") }}</label>
            <Calendar v-model="secondreminderdate" :showTime="true" :min-date="min_second_reminder_date"
                      :disabledDays="[0,6]" dateFormat="dd.mm.yy" @date-select="setDates('secondreminder')"/>
            <small></small>
          </div>

          <div class="p-field p-col-3" v-show="originalsurvey">
            <label class="required">{{ $t("form.step3.fields.closingdate.label") }}</label>
            <Calendar :class="{'p-invalid': validationErrors.closing_date && submitted}" v-model="closingdate"
                      :showTime="true" :min-date="min_closing_date" :disabledDays="[0,6]" dateFormat="dd.mm.yy"
                      @date-select="setDates('closing')"/>
            <small v-show="validationErrors.closing_date && submitted"
                   class="p-error">{{ $t("form.step1.fields.closingdate.error") }}</small>
          </div>

          <div class="p-field p-col-6" v-show="originalsurvey && show_synthesis_required_button">
            <label class="p-mr-2 required">{{ $t("form.step3.fields.synthesis_required.label") }}</label>
            <i class="pi pi-question-circle p-mb-2" v-tooltip="$t('request_detail.tooltips.synthesis')"></i>

            <SelectButton v-model="synthesis_required"
                          :options="[{name: $t('misc.yes'), value: true}, {name: $t('misc.no'), value: false}]"
                          optionLabel="name" optionValue="value" :allow-empty="false"/>
          </div>

          <div class="p-field p-col-6" v-show="originalsurvey">
            <label class="required">{{ $t("form.step3.fields.resulttime.label") }}</label>
            <Dropdown :class="{'p-invalid': validationErrors.receipt_time_limit && submitted}"
                      v-model="receipt_time_limit" :options="receipt_time_limits" optionLabel="name" optionValue="value"
                      :placeholder='$t("form.step3.fields.resulttime.placeholder")'/>
            <small v-show="validationErrors.receipt_time_limit && submitted"
                   class="p-error">{{ $t("form.step1.fields.receipt_time_limit.error") }}</small>
          </div>

          <Timeline layout="horizontal" align="alternate">
            <template #opposite>
              &nbsp;
            </template>
            <template #content="slotProps">
              {{ slotProps.item }}
            </template>
          </Timeline>

        </div>
      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button :label="$t('misc.back')" @click="prevPage()" icon="pi pi-angle-left"/>
          <Button :label="$t('misc.next')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import SurveyService from "@/Services/survey.service"
import SurveyModelsManager from "@/views/config/SurveyModelsManager";
import QuestionModelsManager from "@/views/config/QuestionModelsManager";
import {generalhelper} from "@/helpers/generalhelper";
import FacultiesService from "@/Services/faculties.service";
import RequestService from "@/Services/request.service";
import {requesthelper} from "@/helpers/requesthelper";

export default {
  name: 'Surveyparams',
  components: {QuestionModelsManager, SurveyModelsManager},
  computed: {
    originalsurvey: {
      get() {
        return this.$store.state.request.originalsurvey
      },
      set(value) {
        this.$store.commit('request/setOriginalSurvey', value)
      }
    },
    sendingdate: {
      get() {
        return this.$store.state.request.sendingdate
      },
      set(value) {
        this.$store.commit('request/setSendingDate', value)
      }
    },
    firstreminderdate: {
      get() {
        return this.$store.state.request.firstreminderdate
      },
      set(value) {
        this.$store.commit('request/setFirstReminderDate', value)
      }
    },
    secondreminderdate: {
      get() {
        return this.$store.state.request.secondreminderdate
      },
      set(value) {
        this.$store.commit('request/setSecondReminderDate', value)
      }
    },
    closingdate: {
      get() {
        return this.$store.state.request.closingdate
      },
      set(value) {
        this.$store.commit('request/setClosingDate', value)
      }
    }
  },
  data() {
    return {
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
      survey_type: 'existing',
      survey_modifications: null,
      original_survey_id: null,
      min_sending_date: null,
      min_first_reminder_date: null,
      min_second_reminder_date: null,
      min_closing_date: null,
      receipt_time_limit: null,
      synthesis_required: false,
      surveys: [],
      receipt_time_limits: generalhelper.getReceiptTimeLimits(this.$t),
      is_for_hec_lecture: false,
      faculty_requires_synthesis: false,
      show_synthesis_required_button: false,
      validationErrors: {},
      submitted: false,
    }
  },
  beforeMount() {
    SurveyService.getSurveysByFacultyName(this.$store.state.request.faculty).then(response => {
      this.surveys = (this.$store.state.request.students.length < 5)
        ? response.data.filter(survey => survey._id === "Questionnaire Questions Ouvertes | Questionnaire Open Questions")
        : response.data
      this.setDates()
    })
  },
  mounted() {
    //Get faculties that allow synthesis option button to be displayed
    FacultiesService.getFaculties('enables_synthesis_option=true').then(result => {
      result.data.forEach(faculty => {
        if (this.$store.state.request.faculty === faculty.name) {
          this.faculty_requires_synthesis = true
        }
      })
      this.show_synthesis_required_button = this.$store.state.request.students.length >= 10 && (!this.$store.state.request.is_for_hec_lecture || !this.faculty_requires_synthesis)
    })
  },
  methods: {
    nextPage() {
      this.submitted = true;
      if (this.validateForm()) {
        this.$emit('next-page', {
          formData: {
            //TODO save survey according to survey type
            survey_type: this.survey_type,
            original_survey: this.originalsurvey,
            survey_modifications: this.survey_modifications,
            sending_date: this.sendingdate,
            first_reminder_date: this.firstreminderdate,
            second_reminder_date: this.secondreminderdate,
            closing_date: this.closingdate,
            receipt_time_limit: this.receipt_time_limit,
            synthesis_required: this.synthesis_required || (this.faculty_requires_synthesis || this.$store.state.request.is_for_hec_lecture)
          }, pageIndex: 2
        });
      }
    },
    prevPage() {
      this.$emit('prev-page', {pageIndex: 2});
    },
    setOriginalSurvey(survey) {
      this.originalsurvey = survey
    },
    setOriginalSurveyId(event) {
      this.original_survey_id = event.value._id
    },
    validateForm() {
      this.originalsurvey = this.originalsurvey.json_definition ? this.originalsurvey.json_definition : this.originalsurvey

      !this.sendingdate ? this.validationErrors['sending_date'] = true : delete this.validationErrors['sending_date']
      !this.firstreminderdate ? this.validationErrors['first_reminder_date'] = true : delete this.validationErrors['first_reminder_date']
      !this.closingdate ? this.validationErrors['closing_date'] = true : delete this.validationErrors['closing_date']
      !this.originalsurvey ? this.validationErrors['originalsurvey'] = true : delete this.validationErrors['originalsurvey']
      !this.survey_type ? this.validationErrors['surveytype'] = true : delete this.validationErrors['surveytype']
      this.survey_type === 'custom' && !this.survey_modifications ? this.validationErrors['survey_modifications'] = true : delete this.validationErrors['survey_modifications']
      !this.receipt_time_limit ? this.validationErrors['receipt_time_limit'] = true : delete this.validationErrors['receipt_time_limit']

      this.sendingdate > this.firstreminderdate
        ? this.validationErrors['first_reminder_date'] = true : delete this.validationErrors['first_reminder_date']
      this.secondreminderdate !== null && this.firstreminderdate > this.secondreminderdate
        ? this.validationErrors['second_reminder_date'] = true : delete this.validationErrors['second_reminder_date']
      this.firstreminderdate > this.closingdate
        ? this.validationErrors['closing_date'] = true : delete this.validationErrors['closing_date']

      return !Object.keys(this.validationErrors).length;
    },
    setDatesAccordingToSurveyType() {
      if (this.survey_type === 'custom') {
        let min_sending_date = generalhelper.addDays(new Date(), 7)
        if (this.sendingdate) {
          this.sendingdate = this.sendingdate < min_sending_date ? min_sending_date : this.sendingdate
        }
        this.setDates('sending')
        this.min_sending_date = min_sending_date
        this.firstreminderdate = null
        this.closingdate = null
        this.$toast.add({
          severity: 'info',
          summary: 'Info',
          detail: this.$t('form.step3.fields.surveytype.custom.warning'),
          life: 5000
        });
      } else {
        if (this.survey_modifications !== null) {
          this.$confirm.require({
            target: event.currentTarget,
            message: this.$t('form.step3.fields.surveytype.confirmation'),
            acceptLabel: this.$t('misc.yes'),
            rejectLabel: this.$t('misc.no'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              this.survey_modifications = null
            },
            reject: () => {
              this.survey_type = 'custom'
            }
          })
        }
        this.setDates('sending')
      }
    },
    setDates(event) {
      this.min_sending_date = new Date()
      this.min_first_reminder_date = generalhelper.addDays(this.min_sending_date, 1)
      this.min_second_reminder_date = generalhelper.addDays(this.min_first_reminder_date, 2)
      this.min_closing_date = generalhelper.addDays(this.min_sending_date, 5)
      function adjustWeekend(date) {
        let dayOfWeek = date.getDay() // 0 = Sunday, 6 = Saturday
        if (dayOfWeek === 6) return generalhelper.addDays(date, 2) // If Saturday → +2 jours (Monday)
        if (dayOfWeek === 0) return generalhelper.addDays(date, 1) // If Sunday → +1 jour (monday)
        return date
      }

      switch (event) {
        case 'sending':
          if (this.firstreminderdate === null && this.sendingdate) this.firstreminderdate = adjustWeekend(generalhelper.addDays(this.sendingdate, 3))
          if (this.closingdate === null && this.sendingdate) this.closingdate = adjustWeekend(generalhelper.addDays(this.sendingdate, 10))
          break
        case 'firstreminder':
          if (this.closingdate === null && this.firstreminderdate) this.closingdate = adjustWeekend(generalhelper.addDays(this.firstreminderdate, 7))
          break
        case 'secondreminder':
          if (this.closingdate === null && this.secondreminderdate) this.closingdate = adjustWeekend(generalhelper.addDays(this.secondreminderdate, 10))
          break
        default:
          break
      }
    },
  }
}
</script>
<style>
iframe {
  height: 300px;
}
</style>
