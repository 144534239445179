<template>
  <div class="p-grid" v-show="!loading || !is_allowed_to_see_results">
    <div class="p-col-2">
      <Button @click="$router.push({name:'request_detail'})" icon="pi pi-backward"
              class="p-button-text"
              :label="$t('misc.back')"/>
    </div>
  </div>

  <h2 v-if="loading">{{ $t('misc.loading') }} </h2>
  <h2 v-if="!loading && !is_allowed_to_see_results">{{ $t('misc.results_not_yet_available') }}</h2>

  <table>
    <thead>
    <tr>
      <td>
        <div class="header-space">&nbsp;</div>
      </td>
    </tr>
    </thead>

    <tbody>
    <tr>
      <td>
        <div class="content">

          <i id="hidden_graphs_warning" v-if="!loading && current_role==='admin' && survey_results.length < 5">{{ $t('misc.hidden_graphs') }}</i>
          <div class="request-info">
            <h1 v-show="!loading">{{ $t('request_results.title') + ' no ' + request?.number }}</h1>
            <p v-show="!loading" v-if="current_role==='admin'">{{ request?.teaching_name }} -
              {{ request?.semester === 'P' ? $t('misc.spring') : $t('misc.autumn') }}
              {{ request?.semester === 'P' ? request?.academic_year + 1 : request?.academic_year }}</p>
            <p v-show="!loading" v-if="current_role==='admin'">{{ request?.speakers.map(speaker => speaker.name).join(', ') }}</p>
            <h4 v-show="!loading"> {{ $t('request_results.participants') }} : {{ survey_results.length }} </h4>
          </div>

          <div v-if="!loading" class="sa-question" id="attestation">
            <h2 class="title">{{ $t('request_results.attestation.title') }}</h2>

            <p id="attestation_body">{{ $t('request_results.attestation.body_1') }}</p>
            <ul id="speakers">
              <li v-for="speaker in request?.speakers">
                <b>{{ speaker.name }}</b>
              </li>
            </ul>
            <p>{{ $t('request_results.attestation.body_2') }}</p>

            <p class="p-mt-5">{{ $t('request_results.attestation.body_3') }}</p>
            <table id="teaching_info" class="p-col-12">
              <tr>
                <th>{{ $t('request_results.attestation.teaching_name') }}</th>
                <th>{{ $t('form.step1.fields.semester.label') }}</th>
                <th>{{ $t('request_results.participants') }}</th>
              </tr>
              <tr>
                <td >{{ request?.teaching_name }}</td>
                <td>{{ request?.semester === 'P' ? $t('misc.spring') : $t('misc.autumn') }} {{request?.semester === 'P' ? request?.academic_year + 1 : request?.academic_year}}</td>
                <td>{{ survey_results?.length }}</td>
              </tr>
            </table>

            <p id="signature_block">Lausanne, {{ $t('misc.on_date') }} {{
                new Date().toLocaleDateString($i18n.locale, {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })
              }}
            </p>
          </div>

          <Toolbar v-show="!loading && is_allowed_to_see_results">
            <template #start>
              <Button @click="plotsToImages" icon="pi pi-file-pdf" class="p-ml-2 p-button-info"
                      :label="$t('request_results.export_button_label')"/>
            </template>

            <template #end>
            </template>
          </Toolbar>

          <!--    Matrix  questions     -->
          <div id="matrix_graphs">
            <h3 v-show="!loading" class="section-title">{{ $t('request_results.graphs_title') }}</h3>
            <div v-show="display_graphs" id="survey_viz_matrix"></div>
            <div v-show="display_graphs" id="survey_viz_matrix_inverted"></div>
          </div>

          <!--    Participants info     -->
          <div id="second-page">
            <h3 v-show="!loading" class="section-title">{{ $t('request_results.freqs_and_percentages') }}</h3>
            <div id="participants_info" class="p-grid p-jc-center p-mt-5">
              <table id="radiogroup_questions" class="p-col-10">
                <tr>
                  <th v-for="question of radiogroup_results">{{ question.title }}</th>
                </tr>
                <tr>
                  <td v-for="question of radiogroup_results">
                    <div v-for="choice of question.choices"><b>{{ choice.label }}</b> : {{ choice.value }}</div>
                  </td>
                </tr>
              </table>
            </div>
            <!--    Table viz for matrix questions     -->
            <div v-show="display_graphs" id="survey_viz_table"/>
          </div>

          <!--    Additional radiogroup questions     -->
          <div v-if="other_questions_results.length > 0" id="other_questions" class="p-grid p-jc-center p-mt-2" style="border: none">
            <table v-for="question of other_questions_list" id="other_questions" class="sa__matrix-table p-mt-2">
              <tr>
                <th></th>
                <th v-for="choice of question.choices_titles">{{ choice }} </th>
              </tr>
              <tr>
                <td>{{ question.title }}</td>
                <td v-for="choice of question.choices_titles">{{ other_questions_results[question.name][choice] }}</td>
              </tr>
            </table>
          </div>

          <!--    Open questions     -->
          <div v-if="comments_array.length !== 0" id="comments">
            <h3 v-show="!loading" class="section-title">{{ $t('request_results.comments') }}</h3>
            <DataTable :value="comments_array" showGridlines table-style="">
              <Column v-for="column in comments_array_columns" :field="column.field" :header="column.header" :style="comments_array_column_width"></Column>
            </DataTable>
          </div>
        </div>

      </td>
    </tr>
    </tbody>

    <tfoot>
    <tr>
      <td>
        <div class="footer-space"></div>
      </td>
    </tr>
    </tfoot>
  </table>

  <div class="header">
        <span id="header_title">{{ $t('request_results.header.title') }}</span>
        <span id="header_subtitle">
          <ul>
            <li>No {{ request?.number }} - {{ request?.teaching_name }} - {{ request?.semester === 'P' ? $t('misc.spring') : $t('misc.autumn') }} {{ request?.semester === 'P' ? request?.academic_year + 1 : request?.academic_year }}</li>
            <li>{{ request?.speakers.map(speaker => speaker.name).join(', ') }}</li>
            <li>{{ survey_results?.length }} {{ $t('misc.respondents') }}</li>
          </ul>
        </span>
      <img id="header_logo" class="p-col-2" src="../../../src/assets/header_unil.png" alt="header_logo">
  </div>
</template>

<script>
import 'survey-analytics/survey.analytics.min.css';
import RequestService from "@/Services/request.service";
import ResultsService from "@/Services/results.service";
import {useRoute} from "vue-router";
import {Model} from "survey-core";
import * as SurveyAnalytics from "survey-analytics";
import {
  Matrix,
  PlotlySetup,
  VisualizationManager,
  VisualizationPanel,
  VisualizerBase,
  WordCloud
} from "survey-analytics";
import {generalhelper} from "@/helpers/generalhelper";
import {resulthelper} from "@/helpers/resulthelper";
import {requesthelper} from "@/helpers/requesthelper";

export default {

  name: 'request-results',
  mounted() {
    this.current_role = sessionStorage.getItem('currentrole') ?? ''

    Promise.all([
      RequestService.getRequest(useRoute().params.id),
      ResultsService.getResults(useRoute().params.id)
    ])
      .then(data => {
        this.request = data[0].data.result
        this.survey = JSON.stringify(JSON.parse(this.request.original_survey)).normalize()
        this.is_allowed_to_see_results = (this.current_role === 'user' && this.request?.status === requesthelper.RESULTS_AVAILABLE) || this.current_role === 'admin'
        if (this.is_allowed_to_see_results) {
          this.plotlySetup()
          data[1].data.forEach(result => {
            if (result.json_definition) {
              this.survey_results.push(JSON.parse(result.json_definition.normalize()))
            }

          })
          this.render()
        } else {
          this.loading = false
        }
      })
  },
  data() {
    return {
      request: null,
      survey: null,
      survey_results: [],
      open_questions_list: [],
      matrix_questions_list: [],
      radiogroup_questions_list: [],
      other_questions_list: [],
      other_questions_results: [],
      comments_array: [],
      comments_array_columns: [],
      comments_array_column_width: '',
      radiogroup_results: [],
      loading: true,
      display_graphs: true,
      is_allowed_to_see_results: false,
      current_role: ''
    }
  },
  methods: {
    plotlySetup(){
      PlotlySetup
        .onPlotCreating
        .add((model, options) => {
          resulthelper.setGraphDisplayOptions(options, model);
        });
    },
    render() {
      this.display_graphs = (this.current_role === 'user' && this.survey_results.length >= 5) || this.current_role === 'admin'

      VisualizerBase.customColors = ["dimgray", "gainsboro", "steelblue", "skyblue", "lightsalmon", "salmon"];
      VisualizationManager.unregisterVisualizerForAll(WordCloud);
      const survey = new Model(this.survey);
      survey.locale = this.$i18n.locale
      this.parseQuestions(survey)
      this.renderProfileQuestions(this.radiogroup_questions_list, this.radiogroup_results)
      this.renderOtherRadiogroupQuestions(this.other_questions_list)
      if (this.display_graphs) {
        this.renderMatrixQuestions()
        this.renderTableQuestions()
      }
      this.renderOpenQuestions()
      this.loading = generalhelper.plotsToImages()
    },
    parseQuestions(survey) {
      survey.getAllQuestions().forEach(question => {
        if (question.getType() === 'comment' || question.getType() === 'text') {
          this.open_questions_list.push(question)
        } else if (question.getType() === 'matrix') {
          this.matrix_questions_list.push(question)
        } else if (question.getType() === 'radiogroup') {
          if(question.jsonObj.category === 'added_question') {
            this.other_questions_list.push(question)
          } else {
            this.radiogroup_questions_list.push(question)
          }
        }
      })
    },
    renderMatrixQuestions() {
      const viz_matrix_options = {
        allowHideQuestions: false,
        showPercentages: true,
        showOnlyPercentages: true,
        allowDynamicLayout: false,
        showHeader: false,
      }
      let displayed_matrix_questions = this.matrix_questions_list.filter(question => question?.jsonObj.hide_graph_results !== true)
      let normal_colors_matrix_questions = displayed_matrix_questions.filter(question => question?.jsonObj.inverted_colors !== true)
      let inverted_colors_matrix_questions = displayed_matrix_questions.filter(question => question?.jsonObj.inverted_colors === true)

      const viz_matrix_normal_colors = new VisualizationPanel(
        normal_colors_matrix_questions,
        this.survey_results,
        viz_matrix_options
      );
      viz_matrix_normal_colors.locale = this.$i18n.locale
      viz_matrix_normal_colors.showHeader = false;

      normal_colors_matrix_questions.forEach(question => {
        let visualizer = viz_matrix_normal_colors.getVisualizer(question.name);
        //force reload for graphs not displaying - remove ASAP
        if (!(visualizer instanceof SurveyAnalytics.MatrixPlotly)) {
          this.$router.go()
        }
        visualizer !== undefined ? visualizer.showHeader = false : null;
        visualizer.chartTypes = ["stackedbar"] //Hiding chartType selector by restricting choices
        visualizer.setChartType("stackedbar");
      })

      viz_matrix_normal_colors.render(document.getElementById("survey_viz_matrix"));

      const viz_matrix_inverted_colors = new VisualizationPanel(
        inverted_colors_matrix_questions,
        this.survey_results,
        viz_matrix_options
      );
      viz_matrix_inverted_colors.locale = this.$i18n.locale
      viz_matrix_inverted_colors.showHeader = false;

      inverted_colors_matrix_questions.forEach(question => {
        let visualizer = viz_matrix_inverted_colors.getVisualizer(question.name);
        //force reload for graphs not displaying - remove ASAP
        if (!(visualizer instanceof SurveyAnalytics.MatrixPlotly)) {
          this.$router.go()
        }
        visualizer !== undefined ? visualizer.showHeader = false : null;
        visualizer.chartTypes = ["stackedbar"] //Hiding chartType selector by restricting choices
        visualizer.setChartType("stackedbar");
      })

      VisualizerBase.customColors = ["dimgray", "gainsboro",  "salmon", "lightsalmon", "skyblue", "steelblue"];
      viz_matrix_inverted_colors.render(document.getElementById("survey_viz_matrix_inverted"));
    },
    renderTableQuestions() {
      /**
       * Creates a custom visualizer for the matrix question type that shows the results as a table
       * @param question
       * @param data
       * @param options
       * @returns {module:matrix.Matrix}
       * @constructor
       */
      function CustomMatrixVisualizer(question, data, options) {
        function renderHeader(table, visualizer) {
          const header = document.createElement("tr");
          header.appendChild(document.createElement("th"));
          visualizer.valuesSource().reverse().forEach(function (value) {
            const th = document.createElement("th");
            th.innerHTML = value.text;
            header.appendChild(th);
          });
          table.appendChild(header);
          const total_header = document.createElement("th");
          total_header.innerHTML = "Total";
          header.appendChild(total_header);
        }

        function renderRows(table, visualizer) {
          const data = visualizer.getData();
          visualizer.getSeriesLabels().forEach(function (label, rowIndex) {
            let total_value = 0
            let total_percentage = 0
            const tr = document.createElement("tr");
            const rowLabel = document.createElement("td");
            rowLabel.innerHTML = label;
            tr.appendChild(rowLabel);

            visualizer.valuesSource().forEach((_, columnIndex) => {
              const cell = document.createElement("td");
              //TODO understand why transpose is needed
              if (data.length === 1) {
                //define sum as the sum of all values from data2
                let sum = 0;
                sum = data[0].reduce((a, b) => a + b, 0);
                let data2 = transpose(data)

                total_value += data2[columnIndex][rowIndex]
                total_percentage += Math.round((data2[columnIndex][rowIndex] / sum) * 100)
                cell.innerHTML =
                  data2[columnIndex][rowIndex] +
                  " (" +
                  Math.round((data2[columnIndex][rowIndex] / sum) * 100) +
                  "%)";
                tr.appendChild(cell);
              } else {
                let sum = 0;
                data.forEach(function (colRow) {
                  sum += colRow[rowIndex];
                });
                total_value += data[columnIndex][rowIndex]
                total_percentage += Math.round((data[columnIndex][rowIndex] / sum) * 100)
                cell.innerHTML =
                  data[columnIndex][rowIndex] +
                  " (" +
                  Math.round((data[columnIndex][rowIndex] / sum) * 100) +
                  "%)";
                tr.appendChild(cell);
              }
            });
            //add total row
            const cell = document.createElement("td");
            cell.innerHTML = total_value + " (" + total_percentage + "%)";
            cell.innerHTML = cell.innerHTML.replace(/(99%|101%|102%)/, "100%");

            tr.appendChild(cell);

            table.appendChild(tr);
          });
        }

        const transpose = function (a) {
          return Object.keys(a[0]).map(function (c) {
            return a.map(function (r) {
              return r[c];
            });
          });
        }
        const renderContent = function (contentContainer, visualizer) {
          const table = document.createElement("table");
          table.className = "sa__matrix-table";
          renderHeader(table, visualizer);
          renderRows(table, visualizer);
          contentContainer.appendChild(table);
        };

        return new Matrix(question, data,
          {
            renderContent: renderContent, dataProvider: options.dataProvider
          },
          "matrix_table"
        );
      }

      VisualizationManager.unregisterVisualizer("matrix", SurveyAnalytics.MatrixPlotly)
      VisualizationManager.registerVisualizer("matrix", CustomMatrixVisualizer)

      const viz_table_options = {
        showPercentages: true,
        showOnlyPercentages: true,
        allowTransposeData: true,
        allowDynamicLayout: false,
        allowHideQuestions: false,
        showHeader: false,
      }

      const viz_table = new VisualizationPanel(
        this.matrix_questions_list,
        this.survey_results,
        viz_table_options
      );
      viz_table.locale = this.$i18n.locale
      viz_table.showHeader = false;
      this.matrix_questions_list.forEach(function (question) {
        viz_table.getVisualizer(question.name).showHeader = false
      })
      viz_table.render(document.getElementById("survey_viz_table"));
    },
    renderOpenQuestions() {
      this.open_questions_list.forEach(open_question => {
        let question_header = open_question.title.default || open_question.title[this.$i18n.locale] || open_question.title
        this.comments_array_columns.push({header: question_header, field: open_question.name})
        this.comments_array_column_width = 'width: ' + (100 / this.open_questions_list.length) + '%'
      })

      const comments_array = this.survey_results.map(result => {
        const result_by_user = {}
        let user_did_not_answer_any_open_question = true
        for (const open_question of this.open_questions_list) {
          if(result[open_question.name] !== undefined) {
            user_did_not_answer_any_open_question = false
          }
          result_by_user[open_question.name] = result[open_question.name]
        }

        return user_did_not_answer_any_open_question ? {} : result_by_user
      }).filter(result_by_user => Object.keys(result_by_user).length > 0)

      this.comments_array.push(...comments_array)
    },
    renderProfileQuestions(questions, results) {
      questions.forEach(question => {
        let choices = []
        question.getChoices().forEach(choice => {
          choices.push({label: choice.value, value: 0})
        })
        results.push({question: question.name, title: question.title, choices})
      })

      this.survey_results.forEach(answer => {
        Object.keys(answer).forEach(question => {
          results.forEach(result => {
            if (question === result.question) {
              result.choices.forEach(choice => {
                if (answer[question] === choice.label) {
                  choice.value++
                }
              })
            }
          })
        })
      })
      results.forEach(result => {
        result.choices = result.choices.filter(choice => choice.value !== 0)
      })
    },
    renderOtherRadiogroupQuestions(questions) {
      questions.forEach(question => {
        let choices_titles = question.getChoices().map(choice => choice.text);
        choices_titles.push('Total');
        question.choices_titles = choices_titles;

        this.survey_results.forEach(answer => {
          const choice = answer[question.name];
          if (choice) {
            if (!this.other_questions_results[question.name]) {
              this.other_questions_results[question.name] = {};
              this.other_questions_results.length++
            }
            this.other_questions_results[question.name][choice] = this.other_questions_results[question.name][choice] || { value: 0, percentage: 0 };
            this.other_questions_results[question.name][choice].value++;
            this.other_questions_results[question.name][choice].percentage = Math.round((this.other_questions_results[question.name][choice].value / this.survey_results.length) * 100);
          }
        });
      });

      this.other_questions_list.forEach(question => {
        question.choices_titles.forEach(choice => {
          const result = this.other_questions_results[question.name][choice];
          this.other_questions_results[question.name][choice] = result ? `${result.value} (${result.percentage}%)` : '0 (0%)';
        });

        let total = 0;
        question.choices_titles.forEach(choice => {
          const result = this.other_questions_results[question.name][choice];
          total += parseInt(result.split(' ')[0]);
        });
        this.other_questions_results[question.name]['Total'] = `${total} (100%)`;
      });
    },
    plotsToImages() {
      //wait for the plots to be rendered
      let target = event.target
      this.display_graphs = this.survey_results.length >= 5
      setTimeout(() => {
        if(navigator.userAgent.indexOf('Firefox') > -1 || (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1)) {
          this.$confirm.require({
            message: this.$t('misc.supported_browsers_reminder'),
            target: target,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: this.$t('misc.yes'),
            rejectLabel: this.$t('misc.no'),
            accept: () => {
              document.title = this.request.teaching_name;
              window.print()
            }
          })
        } else {
          document.title = this.request.teaching_name;
          window.print()
        }
        this.display_graphs = (this.current_role === 'user' && this.survey_results.length >= 5) || this.current_role === 'admin'
      }, 1000)
    }
  }
}
</script>

<style>

.sa-text-table__container {
  max-height: none!important;
}

.sa-visualizer__footer-content {
  display: block!important;
}

.sa-visualizer__toolbar, #survey_viz_matrix .sa-visualizer__footer, .sa-visualizer__footer>.sa-toolbar__button {
  display: none;
}

#survey_viz_table .sa__matrix-table tr td:first-child, #other_questions tr td:first-child {
  text-align: left;
  padding: 3px;
}

#second-page {
  page-break-before: always;
  page-break-inside: avoid;
}

#radiogroup_questions {
  page-break-inside: avoid;
  break-inside: avoid;
}

#other_questions {
  page-break-inside: avoid;
  break-inside: avoid;
  padding: 16px;
}

#radiogroup_questions {
  border: 1px solid #e2e8f0;
  border-collapse: collapse;
}

#radiogroup_questions td, #radiogroup_questions th {
  border: 1px solid #e2e8f0;
}

#attestation, .header, .footer {
  display: none;
}

.header ul {
  padding: 0;
  margin: 0;
}

.section-title {
  color: #018CCC;
  font-size: 1.3rem;
  text-align:right;
  margin-right: 15px;
}


#comments {
  width: 100%;
}

#comments th {
  text-align: center;
}

#comments td {
  word-break: break-word;
  padding: 8px;
}

img {
  width: 100%;
  margin-right: -2.5%;
}

li {
  list-style: none;
}

tr {
  height: 20px;
}

.sa-question__content {
  background-color: white !important;
  padding: 0;
}

.sa__matrix-table td {
  max-width: 30vw;
  border: 1px solid #e2e8f0;
}

.sa__matrix-table {
  width: 100%;
  border-collapse: collapse;
}

.sa-visualizer__content {
  font-family: SegoeUI, Arial, sans-serif;
}

.open_question_result, .open_question_header {
  border-top: 1px dashed #e2e8f0;
  text-align: left;
  border-right: 1px dashed #e2e8f0;
  margin: 0;
}

.open_question_result > p {
  padding: 10px;
  margin: 0;
  border-right: 1px dashed #e2e8f0;
  text-align: left;
}

@media print {

  body {
    zoom: 90%;
  }

  .header, .header-space {
    display: block;
    height: 100px;
  }

  .header {
    position: fixed;
    top: 0;
    left: 10px;
    font-size: .8rem;
    text-align: left;
  }

  .footer, .footer-space {
    display: block;
    text-align: left;
    height: 20px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: -1;
    height: 20px;
  }

  #header_title {
    font-weight: bold;
    font-size: 1.3rem;
    color: #018CCC;
    margin-top: 3vw;
    margin-bottom: 0;
    width: 100%;
  }

  #header_subtitle {
    text-align:left;
    font-size: 0.7rem;
  }

  #header_logo {
    position: fixed;
    height: 90px;
    width: 140px;
    right: 10px;
    top: -10px;
  }

  .section-title {
    font-size: 1.2rem;
  }

  @page {
    background-color: #F7F7F7FF;
    margin: 2mm;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .title {
    font-size: 1.5rem;
  }

  #teaching_info {
    font-size: 1rem;
  }
  #teaching_info th {
    font-weight: bold;
    width: 30%;
  }

  #attestation, #open_question_result, img, .sa-visualizer__content, .sa-visualizer__footer-content {
    display: block;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /**
  *  To prevent the comments from breaking across pages
  */
  .sa-question {
    display: block;
  }

  .sa-question__title {
    font-size: .8rem;
  }

  #comments {
    display: block;
    page-break-before: always;
  }

  #matrix_graphs {
    page-break-after: always;
  }

  #attestation {
    display: block;
    height: 80vh;
    font-size: 1.2rem;
    padding: 5vw;
    page-break-after: always;
  }

  #attestation_body {
    text-align: center;
  }

  #signature_block {
    margin-top: 30vh;
    text-align: left;
    page-break-after: always;
  }

  h1, h4, button, .footer, div.p-menubar, div.p-menubar-start, div.p-menubar-end, div.p-toolbar, button.p-button, div.p-tabview-nav-container, .p-confirm-popup, #hidden_graphs_warning, .request-info {
    display: none;
  }

  table, .sa-visualizer__footer-content {
    font-size: .7rem!important;
  }

}
</style>
