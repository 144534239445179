export function validateRole() {
  const token = sessionStorage.getItem('token')
  const currentRole = sessionStorage.getItem('currentrole')

  if (!token && !currentRole) return true

  try {
    const decoded = atob(token.split('.')[1])
    return JSON.parse(sessionStorage.userdata).roles.includes(currentRole)
  } catch (error) {
    console.error('Invalid token format:', error)
    return false
  }
}

